// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root_vj6pX {\n  position: fixed;\n  right: 24px;\n  bottom: 24px;\n  z-index: 9999;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  box-shadow: 0 0 10px #666;\n  font-size: 20px;\n  transition: 0.3s;\n  color: #fff;\n}\n.root_vj6pX:hover {\n  box-shadow: 0 0 10px #1890ff;\n}\n.modal_gCtgE {\n  width: 100vw !important;\n  max-width: 100vw;\n  margin: 0;\n  padding: 0;\n  top: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/generator/style.less"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AAIJ;AAFI;EACI,4BAAA;AAIR;AAAA;EACI,uBAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;EACA,MAAA;AAEJ","sourcesContent":["@import 'src/theme';\n\n.root {\n    position: fixed;\n    right: 24px;\n    bottom: 24px;\n    z-index: 9999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    box-shadow: 0 0 10px #666;\n    font-size: 20px;\n    transition: .3s;\n    color: #fff;\n\n    &:hover {\n        box-shadow: 0 0 10px @primary-color;\n    }\n}\n\n.modal {\n    width: 100vw !important;\n    max-width: 100vw;\n    margin: 0;\n    padding: 0;\n    top: 0;\n}\n\n@packageName: react-admin;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"antPrefix": "react-admin",
	"raLibPrefix": "react-admin-ra",
	"primaryColor": "#1890ff",
	"root": "root_vj6pX",
	"modal": "modal_gCtgE"
};
export default ___CSS_LOADER_EXPORT___;
