
        
        
        export default [{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/adviser/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: '修改信息',
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/adviser/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/adviser/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/adviser',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/adviserTree/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/adviserTree/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/adviserTree',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/article/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看内容';

            return props.isEdit ? '编辑' : '创建';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/article/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/article/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/article',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/bankAccount/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/bankAccount/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/bankAccount',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/caseBase/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看详情';
            return props.isEdit ? '编辑内容' : '创建内容';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/caseBase/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/caseBase/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/caseBase',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/contry/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看内容';

            return props.isEdit ? '编辑国家' : '创建国家';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/contry/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/contry/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/country',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/countryMaterial/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看详情';
            return props.isEdit ? '编辑' : '创建';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/countryMaterial/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/countryMaterial/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/countryMaterial',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/customer/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看详情';
            return props.isEdit ? '编辑内容' : '创建内容';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/customer/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/customer/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/customer',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/faq/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看内容';

            return props.isEdit ? '编辑问题' : '创建问题';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/faq/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/faq/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/faq',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/home/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/home/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/',
    title: '首页',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/label/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看标签';
            return props.isEdit ? '编辑标签' : '创建标签';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/label/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/label/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/label',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/level/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看等级';

            return props.isEdit ? '编辑等级' : '创建等级';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/level/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/level/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/level',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/login/index-full.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/login/index-full.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/f/login',
    auth: false,
    layout: false,
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/login/index-portal.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/login/index-portal.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/p/login',
    auth: false,
    layout: false,
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/login/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/login/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/login',
    auth: false,
    layout: false,
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/material/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看详情';
            return props.isEdit ? '编辑' : '创建';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/material/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/material/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/material',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/menus/ActionEdit.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        },{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/menus/MenuEdit.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        },{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/menus/MenuTableSelect.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        },{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/menus/SystemSelect.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        },{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/menus/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/menus/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/menus',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/order/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看订单';
            if (props?.isQuit) return '退款';
            return props.isEdit ? '编辑订单' : '创建订单';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/order/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/order/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/order/list',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/page-configs.js',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        },{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/product/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看详情';
            return props.isEdit ? '编辑内容' : '创建内容';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/product/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/product/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/product',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/questionnaire/QuetionEdit.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        },{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/questionnaire/SortList.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        },{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/questionnaire/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/questionnaire/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/questionnaire',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/role/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => (props.isEdit ? '编辑角色' : '创建角色'),
        width: '70%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/role/RoleSelectTable.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        },{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/role/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/role/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/roles',
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/user/EditModal.jsx',
            
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看用户';

            return props.isEdit ? '编辑用户' : '创建用户';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: '/Users/duoduo/gocode/hkplus-web/src/pages/user/index.jsx',
            component: ()=> import('/Users/duoduo/gocode/hkplus-web/src/pages/user/index.jsx'),
            pagesPath: '/Users/duoduo/gocode/hkplus-web/src/pages',
        
    path: '/users',
}]
    